import {
  EntityLayout,
  EntitySwitch,
  isEntityWith,
} from '@backstage/plugin-catalog';

import { CustomDomainAboutPage } from '@internal/backstage-plugin-dkt_catalog/src/pages/custom-domain-about/CustomDomainAboutPage';
import { CustomSupportGroupPage } from '@internal/backstage-plugin-dkt_catalog/src/pages/support-group-about/CustomSupportGroupPage';
import { DistributionCard } from '@internal/backstage-plugin-dkt_catalog/src/components/DistributionCard/DistributionCard';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import Grid from '@mui/material/Unstable_Grid2';
import { entityWarningContent } from '../common/EntityWarningContent';

function isDomainOrSubdomain() {
  return isEntityWith({
    kind: 'group',
    type: ['domain', 'sub_domain', 'company'],
  });
}

function isNotCompany() {
  return isEntityWith({
    kind: 'group',
    type: ['domain', 'sub_domain'],
  });
}

export const groupPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isDomainOrSubdomain()}>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            {entityWarningContent}
            <Grid xs={12} md={6}>
              <CustomDomainAboutPage />
            </Grid>
            <Grid xs={12} md={6}>
              <EntityOwnershipCard
                variant="gridItem"
                relationsType="aggregated"
              />
            </Grid>
            <Grid spacing={3} width={'100%'}>
              <EntitySwitch>
                <EntitySwitch.Case if={isNotCompany()}>
                  {<DistributionCard />}
                </EntitySwitch.Case>
              </EntitySwitch>
            </Grid>
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          <Grid container spacing={3}>
            {entityWarningContent}
            <CustomSupportGroupPage />
          </Grid>
        </EntityLayout.Route>
      </EntityLayout>
    </EntitySwitch.Case>
  </EntitySwitch>
);
