import { fetchApiRef, useApi } from '@backstage/core-plugin-api';

export const useBackstageApi = () => {
  const { fetch } = useApi(fetchApiRef);

  async function backstageFetch<T>(
    url: string,
    options?: {
      method?: 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE';
      body?: any;
    },
  ) {
    const body = options?.body && JSON.stringify(options.body);

    try {
      const response = await fetch(url, {
        body,
        headers: { 'Content-Type': 'application/json' },
        method: options?.method ?? 'GET',
      });

      if (response.ok) {
        const entity: T = await response.json();
        return entity;
      } else {
        const error = await response.json();
        throw new Error(error.error_description).message;
      }
    } catch (error: any) {
      console.log(
        `Error during backstageFetch with on ${options?.method} ${url}${
          body ? ` with body ${body} ` : ''
        } the error is ${JSON.stringify(error)}`,
      );
      throw new Error(error);
    }
  }

  return { backstageFetch };
};
