import { EntitySwitch, isKind } from '@backstage/plugin-catalog';

import { SystemPage } from '@internal/backstage-plugin-dkt_catalog';

import { UserJourneyPage } from './UserJourneyPage';
import { componentPage } from './ComponentPage';
import { apiPage } from './ApiPage';
import { groupPage } from './GroupPage';
import { userPage } from './UserPage';
import { domainPage } from './DomainPage';
import { defaultEntityPage } from './DefaultEntityPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')}>
      {componentPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>{apiPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('group')}>{groupPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('user')}>{userPage} </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('system')}>
      <SystemPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('domain')}>{domainPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('userJourney')}>
      <UserJourneyPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
