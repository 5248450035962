import {
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  sidebarConfig,
  useSidebarOpenState,
} from '@backstage/core-components';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import ExtensionIcon from '@material-ui/icons/Extension';
import FeedIcon from '@mui/icons-material/Feed';
import HomeIcon from '@material-ui/icons/Home';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import MenuIcon from '@material-ui/icons/Menu';
import { PropsWithChildren } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { SidebarSearchModal } from '@backstage/plugin-search';
import TableChartIcon from '@mui/icons-material/TableChart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';

const classes = {
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
};

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <div style={classes.root}>
      <Link to="/" underline="none" style={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem
          icon={TableChartIcon as any}
          to="strategic-view"
          text="Strategic view"
        />
        <SidebarItem icon={BubbleChartIcon} to="quadrant" text="Quadrant" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarDivider />
        <SidebarItem
          icon={CreateComponentIcon}
          to="register"
          text="Register system"
        />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem
          icon={TrackChangesIcon as any}
          to="https://airtable.com/appWhu5K3NTs5XURY/pagyttV838v5dvazI"
          text="Tech Radar"
          target="_blank"
        />
        <SidebarItem
          icon={AccountTreeIcon as any}
          to="catalog-graph"
          text="Catalog Graph"
        />
        <SidebarItem
          icon={ViewComfyIcon as any}
          to="capability-map"
          text="Capability Map"
        />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarItem
        icon={FeedIcon as any}
        to="https://decathlon.atlassian.net/wiki/x/bARlHg"
        text="Wiki"
        target="_blank"
        rel="noopener"
      />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
