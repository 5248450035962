import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEffect, useState } from 'react';

import { IDPUser } from '@internal/backstage-plugin-models-common/src/schemas';
import { RIGHT } from '@internal/backstage-plugin-models-common/src/enums';
import { useBackstageApi } from './use-backstage-api';
import { useUserProfile } from '@backstage/plugin-user-settings';

export const useUser = () => {
  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();

  const userProfileUid = useUserProfile().displayName.split('/').pop();

  const [user, setUser] = useState<IDPUser>();

  useEffect(() => {
    if (userProfileUid) {
      getUser(userProfileUid);
    }
  }, [userProfileUid]);

  async function getUser(userUid: string) {
    const idpUser = await backstageFetch<IDPUser>(
      `${config.getString('backend.baseUrl')}/api/catalog/users/${userUid}`,
    );
    setUser(idpUser);
  }

  function isAdmin() {
    return user?.rights.includes(RIGHT.ADMIN) ?? false;
  }

  return { user, isAdmin: isAdmin() };
};
