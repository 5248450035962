import { Box, CardContent, Grid } from '@mui/material';
import { Level, PerformanceLevel } from './PerformanceLevel';

import { AboutField } from '@backstage/plugin-catalog';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Status } from './PerformanceStatus';
import { ThemeBenchmark } from './TechPerformanceCard';
import { makeStyles } from '@mui/styles';

interface PerformanceCardContentProps {
  benchmarks: ThemeBenchmark[] | undefined;
}

export const PerformanceCardContent = ({
  benchmarks,
}: PerformanceCardContentProps) => {
  const useStyles = makeStyles(() => ({
    cardContent: {
      flex: 1,
      padding: '50px 50px 50px 50px !important',
    },
    infoCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)',
      marginBottom: '10px',
      position: 'relative',
    },
    techPerformanceCard: {
      position: 'relative',
    },
    techPerformanceDate: {
      alignItems: 'center',
      color: '#687787',
      display: 'flex',
      fontWeight: '500',
      position: 'absolute',
      right: '20px',
      top: '-60px',
    },
  }));

  const classes = useStyles();
  const dateStyle = classes.techPerformanceDate;
  const cardContent = classes.cardContent;
  const currentDate = new Date();

  const dateRange = {
    endDate: currentDate.toISOString().slice(0, 10),
    startDate: currentDateMinusDay(30).toISOString().slice(0, 10),
  };

  function currentDateMinusDay(nbOfDay: number) {
    return new Date(new Date().setDate(currentDate.getDate() - nbOfDay));
  }

  return (
    <CardContent className={`${cardContent}`}>
      <div className={dateStyle}>
        <DateRangeIcon sx={{ marginRight: '10px' }} />
        <p>{`${dateRange.startDate} ~ ${dateRange.endDate}`}</p>
      </div>
      {benchmarks ? (
        <Grid container data-testid="perf-bloc">
          {benchmarks.map((t: ThemeBenchmark) => {
            return (
              t.to_date_theme_level &&
              t.to_date_theme_level !== 'NA' && (
                <Box
                  sx={{
                    marginBottom: '35px',
                    textAlign: 'center',
                    width: '130px',
                  }}
                  key={t.theme_name}
                >
                  <AboutField label={t.theme_name}>
                    <PerformanceLevel
                      from_date={t.from_date_theme_level as Level}
                      level={t.to_date_theme_level as Level}
                      status={t.theme_level_benchmark_status as Status}
                    />
                  </AboutField>
                </Box>
              )
            );
          })}
        </Grid>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            color: '#ee898e',
            display: 'flex',
            fontWeight: 'bold',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <p>
            There are no performance levels to display for the current system
          </p>
        </Box>
      )}
    </CardContent>
  );
};
