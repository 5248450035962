'use client';

import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

interface Notification {
  message: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
}

interface NotificationContextData {
  notification: Notification | null;
  setNotification: (notification: Notification | null) => void;
}

const NotificationContext = createContext<NotificationContextData>({
  notification: null,
  setNotification: () => {},
});

export const useNotification = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const value = useMemo(
    () => ({ notification, setNotification }),
    [notification, setNotification],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
