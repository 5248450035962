import { Divider, Typography } from '@mui/material';
import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';

import { FC } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

export interface DktEntityAboutContentProps {
  entity: Entity;
}

const classes = {
  value: {
    fontSize: '.75rem',
    overflow: 'hidden',
    wordBreak: 'break-word',
    fontWeight: '600',
    color: 'text.primary',
    textTransform: 'uppercase',
  },
  noValue: {
    fontSize: '.75rem',
    overflow: 'hidden',
    wordBreak: 'break-word',
    color: 'text.secondary',
  },
  label: {
    color: 'text.secondary',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0,
  },
};

export const DktEntityAboutContent: FC<DktEntityAboutContentProps> = ({
  entity,
}) => {
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isLocation = entity.kind.toLocaleLowerCase('en-US') === 'location';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  // @ts-ignore
  return (
    <Grid container spacing={1}>
      <Divider />
      <Grid xs={4}>
        <Typography variant="h2" sx={classes.label}>
          Owner
        </Typography>
      </Grid>
      <Grid xs={8} role="owner">
        {ownedByRelations.length > 0 ? (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="user" />
        ) : (
          <Typography
            variant="body2"
            sx={entity?.spec?.owner ? classes.value : classes.noValue}
          >
            {(entity?.spec?.owner as string) || 'No Owner'}
          </Typography>
        )}
      </Grid>
      {(isAPI ||
        isComponent ||
        isResource ||
        entity.spec?.system ||
        partOfSystemRelations.length > 0) && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              System
            </Typography>
          </Grid>
          <Grid xs={8} role="system">
            {partOfSystemRelations.length > 0 ? (
              <EntityRefLinks
                entityRefs={partOfSystemRelations}
                defaultKind="system"
              />
            ) : (
              <Typography
                variant="body2"
                sx={entity?.spec?.system ? classes.value : classes.noValue}
              >
                {(entity?.spec?.system as string) || 'No System'}
              </Typography>
            )}
          </Grid>
        </>
      )}
      {(isSystem ||
        partOfDomainRelations.length > 0 ||
        entity?.spec?.domain) && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Domain
            </Typography>
          </Grid>
          <Grid xs={8} role="domain">
            {partOfDomainRelations.length > 0 ? (
              <EntityRefLinks
                entityRefs={partOfDomainRelations}
                defaultKind="domain"
              />
            ) : (
              <Typography
                variant="body2"
                sx={entity?.spec?.domain ? classes.value : classes.noValue}
              >
                {entity?.spec?.domain
                  ? (entity.spec.domain as string)
                  : 'No Domain'}
              </Typography>
            )}
          </Grid>
        </>
      )}
      {isComponent && partOfComponentRelations.length > 0 && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Parent Component
            </Typography>
          </Grid>
          <Grid xs={8}>
            <EntityRefLinks
              entityRefs={partOfComponentRelations}
              defaultKind="component"
            />
          </Grid>
        </>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        isTemplate ||
        isGroup ||
        isLocation ||
        typeof entity?.spec?.type === 'string') && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Type
            </Typography>
          </Grid>
          <Grid xs={8} role={`entityType`}>
            <Typography
              variant="body2"
              sx={entity?.spec?.type ? classes.value : classes.noValue}
            >
              {entity?.spec?.type ? (entity.spec.type as string) : 'unknown'}
            </Typography>
          </Grid>
        </>
      )}
      {(isAPI ||
        isComponent ||
        typeof entity?.spec?.lifecycle === 'string') && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Lifecycle
            </Typography>
          </Grid>
          <Grid xs={8} role="lifecycle">
            <Typography
              variant="body2"
              sx={entity?.spec?.lifecycle ? classes.value : classes.noValue}
            >
              {entity?.spec?.lifecycle
                ? (entity.spec.lifecycle as string)
                : 'unknown'}
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Maturity Matrix name
            </Typography>
          </Grid>
          <Grid xs={8} role="description">
            <Typography
              variant="caption"
              sx={entity?.spec?.owner ? classes.value : classes.noValue}
            >
              {entity.metadata?.annotations?.[
                'decathlon.net/maturity-matrix'
              ] ?? '-'}
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Sonar Project Key
            </Typography>
          </Grid>
          <Grid xs={8} role="description">
            <Typography
              variant="caption"
              sx={entity?.spec?.owner ? classes.value : classes.noValue}
            >
              {entity.metadata?.annotations?.['sonarqube.org/project-key'] ??
                '-'}
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Github Repository
            </Typography>
          </Grid>
          <Grid xs={8} role="description">
            <Typography
              variant="caption"
              sx={entity?.spec?.owner ? classes.value : classes.noValue}
            >
              {entity.metadata?.annotations?.['github.com/project-slug'] ?? '-'}
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Quapi ID
            </Typography>
          </Grid>
          <Grid xs={8} role="description">
            <Typography
              variant="caption"
              sx={entity?.spec?.owner ? classes.value : classes.noValue}
            >
              {entity.metadata?.annotations?.['decathlon.net/quapi'] ?? '-'}
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              SLOS
            </Typography>
          </Grid>
          <Grid xs={8} role="description">
            <Typography
              variant="caption"
              sx={entity?.spec?.owner ? classes.value : classes.noValue}
            >
              {entity.metadata?.annotations?.['decathlon.net/slos'] ?? '-'}
            </Typography>
          </Grid>
        </>
      )}
      {isLocation && (entity?.spec?.targets || entity?.spec?.target) && (
        <>
          <Grid xs={4}>
            <Typography variant="h2" sx={classes.label}>
              Targets
            </Typography>
          </Grid>
          <Grid xs={8} role="targets">
            <Typography
              variant="body2"
              sx={entity?.spec?.target ? classes.value : classes.noValue}
            >
              {entity?.spec?.target
                ? (entity.spec.target as string)
                : 'unknown'}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
