import { PerformanceStatus, Status } from './PerformanceStatus';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface PerformanceLevelProps {
  level: Level;
  status?: Status;
  from_date: Level | undefined | null;
}

export type Level = 'ELITE' | 'HIGH' | 'MEDIUM' | 'LOW';

const defaultArgs = {
  icon: 'None',
  label: 'Label',
  size: 'medium',
  variant: 'default',
};

const useStyles = makeStyles(() => ({
  eliteLevel: {
    backgroundColor: '#b6f4e1',
    color: '#5abb9d',
  },

  highLevel: {
    backgroundColor: '#ace8b3',
    color: '#23a942',
  },

  largePerfBloc: {
    alignItems: 'center',
    borderRadius: '5px',
    display: 'flex',
    fontSize: '16px',
    height: '36px',
    justifyContent: 'center',
    margin: 'auto',
    padding: '5px',
    width: '76px',
  },
  lowLevel: {
    backgroundColor: '#ffb3b6',
    color: '#e3262f',
  },

  mediumLevel: {
    backgroundColor: '#fdd3bd',
    color: '#ff600a',
  },
  perfBloc: {
    alignItems: 'center',
    borderRadius: '5px',
    display: 'flex',
    fontSize: '16px',
    height: '36px',
    justifyContent: 'space-around',
    margin: 'auto',
    padding: '5px',
    width: '58px',
  },
}));

export const PerformanceLevel = (args: PerformanceLevelProps) => {
  const elitePerf = useStyles().eliteLevel;
  const highLevel = useStyles().highLevel;
  const mediumLevel = useStyles().mediumLevel;
  const lowLevel = useStyles().lowLevel;
  const perfBloc = useStyles().perfBloc;
  const largePerfBloc = useStyles().largePerfBloc;
  const isLargePerfBloc =
    args.level.toLocaleUpperCase() === 'MEDIUM' && !args.from_date;

  function setPerfStyle() {
    return matchCustomStyle(`${args.level.toLowerCase()}Level`);
  }

  function matchCustomStyle(perfSyle: string) {
    switch (perfSyle) {
      case 'eliteLevel':
        return elitePerf;
      case 'highLevel':
        return highLevel;
      case 'mediumLevel':
        return mediumLevel;
      case 'lowLevel':
        return lowLevel;
      default:
        return '';
    }
  }

  return (
    <div
      className={`${setPerfStyle()} ${
        isLargePerfBloc ? largePerfBloc : perfBloc
      }`}
    >
      {args.from_date && args.level && args.status && (
        <Box
          data-testid={`${args.level}-level`}
          sx={{ display: 'flex', justifyContent: 'space-around', width: 100 }}
        >
          {args.level.slice(0, 1)}
          <PerformanceStatus status={args.status} from_date={args.from_date} />
        </Box>
      )}

      {!args.from_date && args.level && (
        <>
          {args.level.slice(0, 1).toLocaleUpperCase() +
            args.level.slice(1).toLowerCase()}
        </>
      )}
    </div>
  );
};

PerformanceLevel.args = defaultArgs;
