import {
  BSSystem,
  CreateShortcutsDTO,
  CreateShortcutsSchema,
} from '@internal/backstage-plugin-models-common';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DKTFormControl, useNotification } from '@internal/common';

import Button from '@mui/material/Button';
import { DialogActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import { IconGenerator } from './IconGenerator';
import InputAdornment from '@mui/material/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  system: BSSystem;
  handleClose: () => void;
  onSubmit: (_: CreateShortcutsDTO) => void;
}

export const UpdateShortcutsForm = ({
  system,
  handleClose,
  onSubmit,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      shortcuts: system.spec.links,
    },
    mode: 'onTouched',
    resolver: yupResolver(CreateShortcutsSchema),
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'shortcuts',
  });

  const { setNotification } = useNotification();
  const onError = (error: any) => {
    setNotification({
      severity: 'error',
      message: error.message,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {fields.map((field, index) => (
        <Grid
          key={field.id}
          container
          sx={{
            marginY: '10px',
            width: '90%',
          }}
        >
          <Grid item>
            <Controller
              name={`shortcuts.${index}.title`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DKTFormControl
                  {...field}
                  required
                  label={'Name'}
                  errors={errors}
                  size="small"
                  sx={{ width: '100%' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconGenerator iconName={field.value} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name={`shortcuts.${index}.url`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DKTFormControl
                  {...field}
                  required
                  label={'Url'}
                  errors={errors}
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                remove(index);
              }}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{ backgroundColor: '#1DB954' }}
          disabled={!isValid}
        >
          Confirm
        </Button>
      </DialogActions>
    </form>
  );
};
