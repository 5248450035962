import DecathlonLogo from './logo/decathlon-logo-simple-white.svg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();
  return (
    <img src={DecathlonLogo} alt="Decathlon Logo" className={classes.svg} />
  );
};

export default LogoIcon;
