import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const capabilityMapPlugin = createPlugin({
  id: 'capability_map',
  routes: {
    root: rootRouteRef,
  },
});

export const CapabilityPage = capabilityMapPlugin.provide(
  createRoutableExtension({
    name: 'CapabilityPage',
    component: () =>
      import('./Pages/CapabilityPage').then(m => m.CapabilityPage),
    mountPoint: rootRouteRef,
  }),
);
