import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const customApiManagementPlugin = createPlugin({
  id: 'custom-api-management',
  routes: {
    root: rootRouteRef,
  },
});

export const CustomApiManagementPage = customApiManagementPlugin.provide(
  createRoutableExtension({
    name: 'CustomApiManagementPage',
    component: () =>
      import('./pages/api-management').then(m => m.ApiManagementPage),
    mountPoint: rootRouteRef,
  }),
);
