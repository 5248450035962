import { IconComponent, useApp } from '@backstage/core-plugin-api';

import { IconLinkVertical } from './IconLinkVertical';
import PublicIcon from '@mui/icons-material/Public';

interface Props {
  iconName: string;
}

export function IconGenerator(props: Props) {
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key
      ? app.getSystemIcon(key) ?? (PublicIcon as IconComponent)
      : (PublicIcon as IconComponent);

  const Icon = iconResolver(props.iconName.toLowerCase());

  return (
    <div data-testid={`${props.iconName}`}>
      <IconLinkVertical
        icon={<Icon fontSize="small" />}
        name={props.iconName}
      />
    </div>
  );
}
