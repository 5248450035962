import Grid from '@mui/material/Unstable_Grid2';
import { entityWarningContent } from './EntityWarningContent';
import {
  EntityGithubInsightsContributorsCard,
  EntityGithubInsightsLanguagesCard,
} from '@roadiehq/backstage-plugin-github-insights';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { DktEntityAboutCard } from './DktEntityAboutCard/DktEntityAboutCard';

export const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid md={6}>
      <DktEntityAboutCard variant="gridItem" />
    </Grid>
    <Grid md={6} xs={12}>
      <EntityGithubInsightsLanguagesCard />
      <EntityGithubInsightsContributorsCard />
    </Grid>
    <Grid md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid md={6}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
  </Grid>
);
