import { SvgIcon, SvgIconProps } from '@mui/material/';

export function SmaxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <defs></defs>
      <rect className="cls-1" width="36" height="35" />
      <g id="btksfx.tif">
        <image
          id="Layer_0"
          width="32"
          height="33"
          transform="translate(1.47 .65)"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAiCAYAAADRcLDBAAAACXBIWXMAAAsSAAALEgHS3X78AAAEQ0lEQVRYhc2YbWiWZRTH/9e21MC2pOHGckJTK4rMVFhBLWi9gWCM6AUkCiMIojAxB33oSx8iiL5EkR8yUgmLzGSmWDbxBWPZi22WLc1FxZo6w2lr07X9+nD973q8uZ89L/WhG27u5z7X+Z/zv891zrmu65H+B1coFwg0SVomqUFSraRzkk75/kjSFyGEsf+CZKZzYAcwwD/XCBdew8BBYANwRSGbJUUCuFXSa5KukXRY0n5JWyT9JOlSSQskLZI0X9IcSdMl9UnaLmlVCGGkFH9ZBGqBr4BR4D1gegH9ZuBjR2UC6ALm/lsSax3qT/zeCGwuAtcG9BrbDdxQjLMALEjJqoAvnQeNlnUA+4r8gDrjAQ4DswsBNgC9Kdn1wBDQlUPqKLCuGBLGVAOfm8guoCqf4jPAILAsJW91Lmzz+1TgR2BXsSSMmw30AWPAS1kKdf66PRljC4GzSSQs2w2cBO4pkcijLunvgPr04OvAOeCBDOBUoMc5cblltwPHgWPAHSUS+dTT8mqucIqdfA1U5AFuNLAjR7YaOAX8DDxeAon7HI2ev3MDuNfztGkSYCPwDfB77nwCTzpCp4E38ibchbYCcMi27kqE6/2VK4r4gn6D1yYOgQedqONAJ1BTBJH37XNNIui0gcVFgNuc4ePAniS5gBudbACfAbUF7Kyw7o5E0A2cSZKuCCKLjIHYfFotbyIuXBDbdPUkNpqJ7Xx/Ijjqeb2sGBLGzAB2Opf6gZWW15sYwAeT4Bv94T2J4AcLZhVLwrgKYmkPEXtGu+VLiOU7DNyfB1sHnACOJIKEeXMpJHIMPk0s1QFguWVv2ubOPJi5xC58MBF0GfBUOSRsY2USXqDS+THoKF+Uob/UPvcljelXP8uKhCSFEF6WdEDSPEmLQwjHJA0qbnayEr7Vz9MJiUN+zi+XhK9+SZWSLvE7vicydBf62R01YZYTaRRoKZcBce95BKgBrgR+I25oKlJ6DfY3AFyVO9DpOfqwTAIPOQdW+z1Za7Zm6K7z2O70wFLicn0WuDMDuBG4exISDwNt/t2eUy03p/TmWD5Eat+SKCTROARcnBp714a3ALfkITIHeMfTMAq8mBqvAPbaR/aGCJhH3B8AbM+dSxt4xQ0mKcVtxJ33Zpf5cWNPpgnYxnpiqz4K5C8C4BHP7QSwFahMjV/rqel2ZCbseJi4sHVkRQpYY50TwGN5CeQA2h3SCU9RfR69JuA24hJ/HTAtQ6eGWDVjJv18QQI54CeIOyaIZbeKjM43Cb4KeBb43jb6KKcjAzcBB4A/fXcTF6yWLEJ23AK8Zd1x4Lxt5O0/Bc+ixN3Tc5LaJF0tqUrxBP6LpCFJw5IqJFUrdsoGSVMkjUv6VtImSS+EEM6XTSJFZrmkJYqH3ZmSZpiUTGZI8a+BXsVD8NshhNFCtsv6fwIIkqYpnrpnShqRdEbSH5JGQgiUYu8vK3CXz+3N3+wAAAAASUVORK5CYII="
        />
      </g>
    </SvgIcon>
  );
}
