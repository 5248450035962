import { SvgIcon, SvgIconProps } from '@mui/material/';

export function DecathlonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <rect width="32" height="32" fill="white" />
      <path
        d="M32 32H0V0H32V32ZM7.7 7.716V24.167H14.2C16.606 24.167 18.261 23.843 20.11 22.131C21.3986 20.9292 22.2397 19.3245 22.495 17.581V24.156H31.9V20.548H26.726V17.566H31.21V14.025H26.726V11.339H31.905V7.716H22.491V14.364C22.273 12.5663 21.4197 10.9058 20.085 9.682C18.256 8.037 16.327 7.716 13.614 7.716H7.7Z"
        fill="#0082C3"
      />
      <path
        d="M13.615 11.2529H11.936V20.5529H13.636C16.876 20.5529 18.405 19.1219 18.405 15.8849C18.405 12.9229 16.725 11.2529 13.615 11.2529Z"
        fill="#0082C3"
      />
    </SvgIcon>
  );
}
