import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';

import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import { EntityLayout } from '@backstage/plugin-catalog';
import { cicdContent } from '../common/CicdContent';
import { overviewContent } from '../common/OverviewContent';

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/adrs" title="ADRs" if={isAdrAvailable}>
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);
