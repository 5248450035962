import { useEffect, useState } from 'react';

import { DistributionChart } from './DistributionChart';
import { DistributionSource } from '@internal/backstage-plugin-models-common';
import { EntityFilterQuery } from '@backstage/catalog-client';
import Grid from '@mui/material/Unstable_Grid2';
import { useEntity } from '@backstage/plugin-catalog-react';

export const DistributionCard = () => {
  const { entity } = useEntity();
  const [filter, setFilter] = useState<EntityFilterQuery>();

  useEffect(() => {
    const entityType = (entity?.spec?.type as string)
      .toLowerCase()
      .replace('_', '');
    setFilter({
      [`spec.${entityType}`]: 'group:default/' + entity?.metadata?.name,
    });
  }, [entity?.metadata?.name]);

  return (
    <Grid container spacing={3}>
      {filter && (
        <>
          <Grid xs={12} md={6}>
            <DistributionChart
              filter={filter}
              title={'Criticality distribution'}
              source={DistributionSource.criticality}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <DistributionChart
              filter={filter}
              title={'Budget by criticality'}
              source={DistributionSource.budget}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
