import {
  BSSystem,
  ShortcutLink,
  UpdateProductDTO,
} from '@internal/backstage-plugin-models-common';
import { Button, DialogContentText, Grid } from '@mui/material/';
import {
  EntityDisplayName,
  EntityRefLink,
} from '@backstage/plugin-catalog-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi, useNotification } from '@internal/common';

import { AddShortcutsForm } from './AddShortcutsForm';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconGenerator } from './IconGenerator';
import Link from '@mui/material/Link';
import { UpdateShortcutsForm } from './UpdateShortcutsForm';
import { makeStyles } from '@mui/styles';

interface Props {
  contentToDisplay: string;
  system: BSSystem;
  isAddModalOpen: boolean;
  isEditModalOpen: boolean;
  onClose: () => void;
  onOpenAddModal: () => void;
}

export function CustomSystemContent({
  contentToDisplay,
  system,
  isAddModalOpen,
  isEditModalOpen,
  onClose,
  onOpenAddModal,
}: Props) {
  const useStyles = makeStyles(theme => ({
    transparentRow: {
      padding: theme.spacing(2, 2),
    },
    greyRow: {
      backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#6b6b6b',
      padding: theme.spacing(2, 2),
    },
    systemDescription: {
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#757575',
      fontWeight: '500',
      padding: '14px 0 14px 0',
    },
    systemLabel: {
      fontWeight: 'bold',
      padding: '14px 0 14px 5px',
      width: '250px',
    },
  }));
  const classes = useStyles();

  const renderEntityRef = (kind: string, name?: string) => {
    if (!name) {
      return '-';
    }
    const cleanName = name?.replaceAll(`${kind.toLowerCase()}:default/`, '');
    return (
      <EntityRefLink
        entityRef={{
          name: cleanName,
          kind,
          namespace: 'default',
        }}
      >
        <EntityDisplayName
          hideIcon={true}
          entityRef={{
            name: cleanName,
            kind,
            namespace: 'default',
          }}
        />
      </EntityRefLink>
    );
  };

  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();
  const { setNotification } = useNotification();

  const onUpdateOrCreateShortcuts = (
    shortcuts: ShortcutLink[],
    isNew = false,
  ) => {
    const payload: UpdateProductDTO = {
      owner: system.spec.owner.split('/')[1],
      backup_owner: system.spec.backupOwner?.split('/')[1],
      lifecycle: system.spec.lifecycle,
      support_group: system.spec.supportGroup.split('/')[1],
      name: system.metadata.title,
      business_criticality_score:
        system.spec.businessCriticalityScore?.toLocaleString(),
      budget: system.spec.budget?.toString(),
      links: isNew ? [...system.spec.links, ...shortcuts] : shortcuts,
    };

    submitRequest(payload, 'PUT');
    onClose();
  };

  async function submitRequest(
    data: any,
    method: 'POST' | 'PUT' | 'GET' | 'DELETE',
  ): Promise<BSSystem | void> {
    try {
      await backstageFetch<BSSystem>(
        `${config.getString('backend.baseUrl')}/api/catalog/systems/${
          system.metadata.name ?? ''
        }`,
        { method, body: data },
      );
      setNotification({
        severity: 'success',
        message: `Shortcut(s) ${
          isAddModalOpen ? 'added' : 'updated'
        } successfully`,
      });
    } catch (error: any) {
      setNotification({
        severity: 'error',
        message: error.message,
      });
    }
  }

  return contentToDisplay.includes('About') ? (
    <Grid container>
      <Grid
        item
        md={12}
        className={classes.greyRow}
        sx={{ display: 'flex', margin: '0', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Domain</div>
        <div className={classes.systemDescription}>
          {renderEntityRef('Group', system.spec.domain)}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.transparentRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Subdomain</div>
        <div className={classes.systemDescription}>
          {renderEntityRef('Group', system.spec.subdomain)}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.greyRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Support Group</div>
        <div className={classes.systemDescription}>
          {renderEntityRef('Group', system.spec.supportGroup)}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.transparentRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Owner</div>
        <div className={classes.systemDescription}>
          {renderEntityRef('User', system.spec.owner)}
        </div>
      </Grid>
      {system.spec.backupOwner && (
        <Grid
          item
          md={12}
          className={classes.greyRow}
          sx={{ display: 'flex', padding: '0 16px!important' }}
        >
          <div className={classes.systemLabel}>Backup Owner</div>
          <div className={classes.systemDescription}>
            {renderEntityRef('User', system.spec.backupOwner)}
          </div>
        </Grid>
      )}
      <Grid
        item
        md={12}
        className={classes.transparentRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Lifecycle</div>
        <div className={classes.systemDescription}>
          {system.spec.lifecycle ?? '-'}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.greyRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Business criticality</div>
        <div className={classes.systemDescription}>
          {system.spec.businessCriticalityLevel ?? '-'}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.transparentRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Budget</div>
        <div className={classes.systemDescription}>
          {system.spec.budget ? `${system.spec.budget.toLocaleString()}€` : '-'}
        </div>
      </Grid>
      <Grid
        item
        md={12}
        className={classes.greyRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Maturity Matrix name</div>
        <div className={classes.systemDescription}>
          {system.metadata?.annotations['decathlon.net/maturity-matrix'] ?? '-'}
        </div>
      </Grid>

      <Grid
        item
        md={12}
        className={classes.transparentRow}
        sx={{ display: 'flex', padding: '0 16px!important' }}
      >
        <div className={classes.systemLabel}>Description</div>
        <div className={classes.systemDescription}>
          {system.metadata?.description}
        </div>
      </Grid>
    </Grid>
  ) : (
    <div data-testid="shortcuts-bloc">
      {system.spec.links?.length ? (
        system.spec.links.map((e, id) => {
          return (
            e.title !== 'Offering' && (
              <Grid
                item
                md={12}
                className={id % 2 ? classes.greyRow : classes.transparentRow}
                sx={{
                  display: 'flex',
                  margin: '0',
                  padding: '10px 16px!important',
                }}
              >
                <Link
                  href={e.url}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    display: 'flex',
                    color: '#000000',
                    fontWeight: '500',
                    ':hover': { textDecoration: 'none' },
                  }}
                >
                  <IconGenerator iconName={e.title} />
                  <Box
                    sx={{
                      marginLeft: '10px',
                    }}
                  >
                    {e.title.charAt(0).toUpperCase() + e.title.slice(1)}
                  </Box>
                </Link>
              </Grid>
            )
          );
        })
      ) : (
        <Box
          sx={{
            color: '#9E9E9E',
            fontWeight: 'bold',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            transform: 'translateY(50%)',
          }}
        >
          <span>No shortcuts available for the current system</span>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: '20px', width: '200px', margin: '25px auto' }}
            onClick={onOpenAddModal}
          >
            Add shortcuts
          </Button>
        </Box>
      )}

      <Dialog
        open={isAddModalOpen || isEditModalOpen}
        onClose={onClose}
        aria-labelledby="shortcuts-dialog-title"
        aria-describedby="shortcuts-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="shortcuts-dialog-title">
          Shortcuts management
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="shortcuts-dialog-description">
            {isAddModalOpen &&
              `Manange your shortcuts for ${system.metadata.title}. Select
            the shortcut you want to add, then add its corresponding url.)`}
            {isEditModalOpen &&
              `Update your shortcuts for ${system.metadata.title}. Update or
            remove the shortcut in the list below.`}
          </DialogContentText>
          {isAddModalOpen && (
            <AddShortcutsForm
              system={system}
              onSubmit={shortcuts =>
                onUpdateOrCreateShortcuts(shortcuts.shortcuts, true)
              }
              handleClose={onClose}
            />
          )}
          {isEditModalOpen && (
            <UpdateShortcutsForm
              system={system}
              handleClose={onClose}
              onSubmit={shortcuts =>
                onUpdateOrCreateShortcuts(shortcuts.shortcuts, false)
              }
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
