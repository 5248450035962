import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box } from '@mui/material/';
import { Level } from './PerformanceLevel';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

export interface PerformanceStatusProps {
  status: Status;
  from_date: Level;
}

export type Status = 'UP' | 'DOWN' | 'NEUTRAL';

function showStatusArrow(status: Status, from_date: Level) {
  const useStyles = makeStyles(() => ({
    statusArrowBackground: {
      '&::before': {
        backgroundColor: '#687787',
        borderRadius: '100px',
        content: "''",
        display: 'block',
        height: '15px',
        position: 'absolute',
        top: '5px',
        transform: 'translateX(5px)',
        width: '15px',
      },
      display: 'flex',
      position: 'relative',
      statusArrow: {
        color: '#ffffff',
        position: 'relative',
      },
    },
    statusArrow: {
      color: '#ffffff',
      position: 'relative',
    },
    statusArrowDown: {
      transform: 'rotate(90deg)',
    },
    statusArrowUp: {
      transform: 'rotate(-90deg)',
    },
  }));
  const classes = useStyles();
  const arrowBackground = classes.statusArrowBackground;
  const statusArrow = classes.statusArrow;
  const statusArrowUp = classes.statusArrowUp;
  const statusArrowDown = classes.statusArrowDown;

  switch (status) {
    case 'UP':
      return (
        <div className={`${arrowBackground}`} data-testid="arrow-up">
          <Tooltip
            title={`Improving values over the period. Previous status: ${from_date}`}
            placement="top"
          >
            <ArrowCircleRightIcon
              className={`${statusArrow} ${statusArrowUp}`}
            />
          </Tooltip>
        </div>
      );
    case 'DOWN':
      return (
        <div className={`${arrowBackground}`} data-testid="arrow-down">
          <Tooltip
            title={`Downgraded values over the period. Previous status: ${from_date}`}
            placement="top"
          >
            <ArrowCircleRightIcon
              className={`${statusArrow} ${statusArrowDown}`}
            />
          </Tooltip>
        </div>
      );
    case 'NEUTRAL':
      return (
        <div className={`${arrowBackground}`} data-testid="arrow-neutral">
          <Tooltip title="Same result than before" placement="top">
            <ArrowCircleRightIcon className={`${statusArrow}`} />
          </Tooltip>
        </div>
      );
    default:
      return '';
  }
}

export const PerformanceStatus = (args: PerformanceStatusProps) => {
  return (
    <Box sx={{ alignSelf: 'baseline', display: 'flex' }}>
      {showStatusArrow(args?.status, args?.from_date)}
    </Box>
  );
};
