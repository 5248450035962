import {
  DatadogIcon,
  DecathlonIcon,
  DocapiIcon,
  DynatraceIcon,
  GraviteeIcon,
  JiraIcon,
  PagerdutyIcon,
  SlackIcon,
  StatuspalIcon,
  WikiIcon,
} from './components/assets/img';

import GitHubIcon from '@mui/icons-material/GitHub';
import { IconComponent } from '@backstage/core-plugin-api';
import PublicIcon from '@mui/icons-material/Public';
import { SmaxIcon } from './components/assets/img/Smax';

export { customSystemOverviewPlugin, CustomSystemOverviewPage } from './plugin';

export { CustomSystemAboutCard } from './components/CustomAboutCard/CustomSystemAboutCard';
export { CustomComponentCard } from './components/CustomComponentCard/CustomComponentCard';
export { TechPerformanceCard } from './components/TechPerformanceCard/TechPerformanceCard';

export * from './components/assets/img';

export const iconList = [
  'slack',
  'github',
  'pagerduty',
  'statuspal',
  'gravitee',
  'dynatrace',
  'datadog',
  'jira',
  'wiki',
  'smax',
  'other',
];

export type IconKey =
  | 'slack'
  | 'github'
  | 'pagerduty'
  | 'statuspal'
  | 'docapi'
  | 'gravitee'
  | 'dynatrace'
  | 'datadog'
  | 'quapi'
  | 'jira'
  | 'smax'
  | 'other'
  | 'wiki';

export const icons: Record<IconKey, IconComponent> = {
  datadog: DatadogIcon as IconComponent,
  docapi: DocapiIcon as IconComponent,
  dynatrace: DynatraceIcon as IconComponent,
  github: GitHubIcon as IconComponent,
  gravitee: GraviteeIcon as IconComponent,
  jira: JiraIcon as IconComponent,
  pagerduty: PagerdutyIcon as IconComponent,
  quapi: DecathlonIcon as IconComponent,
  slack: SlackIcon as IconComponent,
  statuspal: StatuspalIcon as IconComponent,
  smax: SmaxIcon as IconComponent,
  other: PublicIcon as IconComponent,
  wiki: WikiIcon as IconComponent,
};
