import { CardContent, Stack } from '@mui/material';
import { EntityDisplayName, useEntity } from '@backstage/plugin-catalog-react';
import { useEffect, useState } from 'react';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Unstable_Grid2';
import GroupIcon from '@mui/icons-material/Group';
import { InfoCard } from '@backstage/core-components';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

export function CustomDomainAboutPage() {
  const { entity: group } = useEntity();
  const navigate = useNavigate();
  const subdomains = group?.spec?.children as string[];
  const domainParent = group.relations
    ?.filter(relation => relation.type === 'childOf')
    .map(relation => relation.targetRef.split('/')[1])[0];

  const [domainLabel, setDomainLabel] = useState<string>();
  const [subDomainLabel, setSubDomainLabel] = useState<string>();

  useEffect(() => {
    switch (group?.spec?.type) {
      case 'COMPANY':
        setDomainLabel('');
        setSubDomainLabel('Domain(s)');
        break;
      case 'DOMAIN':
        setDomainLabel('Company');
        setSubDomainLabel('Subdomain(s)');
        break;
      case 'SUB_DOMAIN':
        setDomainLabel('Domain');
        setSubDomainLabel('Support Group(s)');
        break;
      default:
        break;
    }
  }, [group?.spec?.type]);

  return (
    <InfoCard title="About">
      <CardContent>
        <Grid container spacing={3}>
          <Stack>
            {domainLabel ? (
              <Stack
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
              >
                <Grid xs={4} sx={{ width: '165px' }}>
                  <Stack spacing={2} direction="row">
                    <PersonIcon />
                    <div>{domainLabel}</div>
                  </Stack>
                </Grid>
                <Chip
                  label={domainParent}
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#FFECD0!important',
                    borderRadius: '100px!important',
                    color: '#FF9800!important',
                    height: '30px!important',
                    margin: '5px!important',
                    padding: '10px!important',
                  }}
                  onClick={() =>
                    navigate(`/catalog/default/group/${domainParent}`)
                  }
                />
              </Stack>
            ) : null}
            <Stack
              sx={{ alignItems: 'center', display: 'flex' }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              <Grid xs={4} sx={{ width: '165px' }}>
                <Stack spacing={2} direction="row">
                  <GroupIcon />
                  <div>{subDomainLabel}</div>
                </Stack>
              </Grid>
              {subdomains?.map((subdomain: string) => (
                <Chip
                  key={subdomain}
                  label={
                    <EntityDisplayName
                      hideIcon={true}
                      entityRef={{
                        name: subdomain,
                        kind: 'Group',
                        namespace: 'default',
                      }}
                    />
                  }
                  onClick={() =>
                    navigate(`/catalog/default/group/${subdomain}`)
                  }
                  sx={{
                    backgroundColor: '#E1E3F5!important',
                    borderRadius: '100px!important',
                    color: '#2E77D0!important',
                    height: '30px!important',
                    margin: '5px!important',
                    padding: '10px!important',
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      </CardContent>
    </InfoCard>
  );
}
