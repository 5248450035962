import { Chip, Tooltip } from '@mui/material';
import { FC } from 'react';

interface DKTLevelChipProps {
  level?: 'Elite' | 'High' | 'Medium' | 'Low';
  size?: 'small' | 'large';
}

export const DKTLevelChip: FC<DKTLevelChipProps> = ({ level, size }) => {
  return (
    <Tooltip title={size === 'small' && level} placement="top">
      <Chip
        sx={{
          borderRadius: '5px',
          fontWeight: 'bold',
          height: '26px',
          width: '23px',
        }}
        color={level ? (level.toLocaleLowerCase() as any) : 'nd'}
        label={size === 'small' ? level?.charAt(0) : level}
      />
    </Tooltip>
  );
};
