import {
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import { forwardRef } from 'react';

export type DKTFCProps = TextFieldProps & {
  label: string;
  name: string;
  errors: FieldErrors;
};

export const DKTFormControl = forwardRef<TextFieldProps, DKTFCProps>(
  ({ label, name, errors, required, placeholder, ...field }, ref) => {
    const isMultiline = field.multiline; // Check if the field should be multiline
    return (
      <FormControl sx={{ minWidth: 400 }}>
        <TextField
          {...field}
          required={required}
          variant="outlined"
          inputRef={ref}
          id={name}
          aria-label={label}
          placeholder={placeholder ?? label}
          label={label}
          error={!!errors[name]}
          multiline={isMultiline}
          rows={isMultiline ? 4 : undefined}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <FormHelperText error id={`${name}-errors`}>
              {message}
            </FormHelperText>
          )}
        />
      </FormControl>
    );
  },
);

DKTFormControl.displayName = 'DKTFormControl';
