import { useEffect, useState } from 'react';

import { BSSystem } from '@internal/backstage-plugin-models-common';
import { EntityFilterQuery } from '@backstage/catalog-client';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';

export const useBSSystems = (filter?: EntityFilterQuery) => {
  const catalogApi = useApi(catalogApiRef);
  const [systems, setSystems] = useState<BSSystem[]>([]);

  useEffect(() => {
    const getSystems = async () => {
      const fetchedSystems = await catalogApi.getEntities({
        filter: { ...(filter || {}), kind: ['system'] },
      });
      if (fetchedSystems?.items) {
        setSystems(fetchedSystems.items as BSSystem[]);
      }
      return [];
    };
    getSystems();
  }, [catalogApi, filter]);

  return { systems };
};
