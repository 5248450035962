import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useEffect, useState } from 'react';

import { InfoCard } from '@backstage/core-components';
import { PerformanceCardContent } from './PerformanceCardContent';
import { SystemEntity } from '@backstage/catalog-model';
import { makeStyles } from '@mui/styles';
import { useEntity } from '@backstage/plugin-catalog-react';

export type ThemeBenchmark = {
  theme_name: string;
  from_date_theme_level: string;
  to_date_theme_level: string;
  theme_level_benchmark_status: string;
  from_date_theme_score_percent: number;
  to_date_theme_score_percent: number;
  theme_score_benchmark_status: string;
  metric_benchmark_list: [];
  error?: string;
  error_description?: string;
};

export function TechPerformanceCard() {
  const useStyles = makeStyles(() => ({
    infoCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)',
      marginBottom: '10px',
      position: 'relative',
    },
    techPerformanceCard: {
      position: 'relative',
    },
  }));
  const classes = useStyles();
  const { entity } = useEntity<SystemEntity>();
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const [themeBenchmarkList, setThemeBenchmarkList] = useState<
    ThemeBenchmark[]
  >([]);
  const [loading, setLoading] = useState(true);
  const techPerformance = classes.techPerformanceCard;
  const infoCard = classes.infoCard;

  const systemId = entity.metadata.name;

  async function fetchData() {
    try {
      const response = await fetch(
        `${config.getString(
          'backend.baseUrl',
        )}/api/delivery_metrics/${systemId}/benchmarks`,
        { method: 'GET' },
      );
      const data = await response.json();

      if (data.benchmarks) {
        setLoading(false);
        setThemeBenchmarkList(data.benchmarks.theme_benchmark_list);
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [entity]);

  return (
    <InfoCard
      title="Tech performance"
      cardClassName={`${techPerformance} ${infoCard}`}
      deepLink={{
        // link: '#',
        link: `${config.getString(
          'deliveryMetricsFront.baseUrl',
        )}/scope/benchmark?uuid=${entity.metadata?.name}`,
        title: 'View more',
      }}
      variant="gridItem"
      noPadding
    >
      {!loading && themeBenchmarkList && themeBenchmarkList.length > 0 && (
        <PerformanceCardContent
          benchmarks={
            themeBenchmarkList.length > 0 ? themeBenchmarkList : undefined
          }
        />
      )}
    </InfoCard>
  );
}
