import {
  BSSystem,
  DistributionSource,
} from '@internal/backstage-plugin-models-common';
import { FC, useEffect, useState } from 'react';
import { PiePlot, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { Box } from '@mui/material';
import { DataType } from './Datatype.interface';
import { EntityFilterQuery } from '@backstage/catalog-client';
import Grid from '@mui/material/Unstable_Grid2';
import { InfoCard } from '@backstage/core-components';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { useBSSystems } from '@internal/common';

export interface Props {
  filter: EntityFilterQuery;
  source: DistributionSource;
  title: string;
}

export const DistributionChart: FC<Props> = ({ filter, source, title }) => {
  const size = {
    height: 500,
  };
  const [datasets, setDatasets] = useState<DataType[]>([]);
  const { systems } = useBSSystems(filter);
  const computeDatasets = (systems: BSSystem[]): DataType[] => {
    if (source === DistributionSource.budget) {
      // Budget logic
      const totalBudgetByCriticalityLevelMap = systems.reduce((acc, system) => {
        const level: string =
          (system.spec?.businessCriticalityLevel as string) || 'N/A';
        const budget: number = system?.spec?.budget ?? 0;
        if (acc.get(level)) {
          acc.set(level, {
            value: (acc.get(level)?.value ?? 0) + budget,
            label: level,
            fullLabel: level,
          });
        } else {
          acc.set(level, { value: budget, label: level, fullLabel: level });
        }
        return acc;
      }, new Map<string, DataType>());
      return [...totalBudgetByCriticalityLevelMap]
        .map(([, value]) => value)
        .filter(value => value.value > 0)
        .sort((a, b) => b.value - a.value);
    }
    if (source === DistributionSource.criticality) {
      // Criticality logic
      const totalCountByCriticalityLevelMap = systems.reduce((acc, system) => {
        const level: string =
          (system.spec?.businessCriticalityLevel as string) || 'N/A';
        if (acc.get(level)) {
          acc.set(level, {
            value: (acc.get(level)?.value ?? 0) + 1,
            label: level,
            fullLabel: level,
          });
        } else {
          acc.set(level, { value: 1, label: level, fullLabel: level });
        }
        return acc;
      }, new Map<string, DataType>());
      return [...totalCountByCriticalityLevelMap]
        .map(([, value]) => value)
        .sort((a, b) => b.value - a.value);
    }
    return [];
  };

  useEffect(() => {
    setDatasets(computeDatasets(systems));
  }, [systems]);

  return (
    <InfoCard title={title}>
      <Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            flexWrap: 'wrap',
          }}
        >
          {datasets.map((dataset, index) => (
            <Box
              key={dataset.label}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: '200px',
                marginX: '1rem',
                marginY: '.5rem',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '100%',
                  backgroundColor: [
                    '#3643BA',
                    '#36BA5A',
                    '#BA9D36',
                    '#BA3639',
                    '#3F4365',
                  ][index % 5],
                  marginRight: '5px',
                }}
              ></div>
              <div>{dataset.label}</div>
            </Box>
          ))}
        </Box>
        {datasets.length ? (
          <ResponsiveChartContainer
            colors={['#3643BA', '#36BA5A', '#BA9D36', '#BA3639', '#3F4365']}
            series={[
              {
                type: 'pie',
                arcLabel: item => {
                  return new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 3,
                  }).format(item.value);
                },
                arcLabelMinAngle: 15,
                innerRadius: 50,
                cornerRadius: 5,
                paddingAngle: 2,
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: {
                  innerRadius: 35,
                  additionalRadius: -30,
                  color: '#ddd',
                },
                data: datasets.map(e => ({
                  label: e.label,
                  value: e.value,
                })),
                valueFormatter: v => {
                  if (source === DistributionSource.budget) {
                    return new Intl.NumberFormat('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(v.value);
                  }
                  return new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 3,
                  }).format(v.value);
                },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontSize: '15px',
                fontWeight: 'bold',
                fill: '#fff',
              },
            }}
            {...size}
          >
            <PiePlot />
          </ResponsiveChartContainer>
        ) : (
          'No data to display'
        )}
      </Grid>
    </InfoCard>
  );
};
