import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const strategicPerformancePlugin = createPlugin({
  id: 'strategic_performance',
  routes: {
    root: rootRouteRef,
  },
});

export const StrategicPerformanceViewPage = strategicPerformancePlugin.provide(
  createRoutableExtension({
    component: () =>
      import('./pages/StrategicViewPage').then(m => m.StrategicViewPage),
    mountPoint: rootRouteRef,
    name: 'StrategicPerformancePage',
  }),
);
