import {
  CustomComponentCard,
  CustomSystemAboutCard,
  TechPerformanceCard,
} from '@internal/backstage-plugin-custom-system-overview';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi, useNotification, useUser } from '@internal/common';

import { EntityLayout } from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';
import { useEntity } from '@backstage/plugin-catalog-react';

export const SystemPage = () => {
  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();
  const { setNotification } = useNotification();
  const { entity } = useEntity();

  const synchronise = async () => {
    try {
      const synchronisedEntity = await backstageFetch<Entity>(
        `${config.getString('backend.baseUrl')}/api/catalog/systems/${
          entity.metadata.name
        }`,
        { method: 'GET' },
      );
      if (synchronisedEntity) {
        setNotification({
          message: 'System updated successfully',
          severity: 'success',
        });
      }
    } catch (error: any) {
      setNotification({
        message: error.message,
        severity: 'error',
      });
    }
  };

  const { isAdmin } = useUser();

  return (
    <EntityLayout
      UNSTABLE_contextMenuOptions={{
        disableUnregister: isAdmin ? 'visible' : 'hidden',
      }}
    >
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <Grid md={6}>
            <CustomSystemAboutCard onSynchronise={synchronise} />
          </Grid>
          <Grid md={6}>
            <TechPerformanceCard />
          </Grid>
          <Grid md={12}>
            <CustomComponentCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="System Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON,
          ]}
          unidirectional={false}
        />
      </EntityLayout.Route>
    </EntityLayout>
  );
};
