import {
  BSSystem,
  CreateShortcutsDTO,
  ShortcutLinkSchema,
} from '@internal/backstage-plugin-models-common';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material/';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DKTFormControl, useNotification } from '@internal/common';

import DialogActions from '@mui/material/DialogActions';
import { IconGenerator } from './IconGenerator';
import InputAdornment from '@mui/material/InputAdornment';
import { iconList } from '../..';
import { yupResolver } from '@hookform/resolvers/yup';

interface ShortcutsModalProps {
  system: BSSystem;
  onSubmit: (_: CreateShortcutsDTO) => void;
  handleClose: () => void;
}

export const AddShortcutsForm = ({
  system,
  onSubmit,
  handleClose,
}: ShortcutsModalProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedShortcut(event.target.value);
  };
  const [selectedShortcut, setSelectedShortcut] = useState<string>('');
  const generateShortcutList = system.spec.links
    .map(link => link.title)
    .includes('smax')
    ? iconList.filter(icon => icon !== 'smax')
    : iconList;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(ShortcutLinkSchema),
  });
  const { setNotification } = useNotification();

  const onError = (error: any) => {
    setNotification({
      severity: 'error',
      message: error.message,
    });
  };

  useEffect(() => {
    if (selectedShortcut) {
      reset({
        title:
          selectedShortcut.slice(0, 1).toUpperCase() +
          selectedShortcut.slice(1),
        url: '',
      });
    }
  }, [selectedShortcut]);

  return (
    <form
      onSubmit={handleSubmit(
        shortcut => onSubmit({ shortcuts: [shortcut] }),
        onError,
      )}
    >
      <Grid
        container
        sx={{
          marginY: '10px',
        }}
      >
        <Grid item>
          <RadioGroup
            row
            aria-labelledby="shortcuts-form-control"
            name="shortcuts-list"
            defaultValue="top"
            onChange={handleChange}
          >
            {generateShortcutList.map(icon => {
              return (
                <FormControlLabel
                  value={icon}
                  key={icon}
                  name={`shortcut-${icon}`}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        '&.v5-MuiRadio-root': {
                          padding: '5px!important',
                          position: 'absolute',
                          right: '0',
                        },
                        '&.v5-MuiFormControlLabel-root': {
                          display: 'flex',
                          justifyContent: 'space-around',
                        },
                      }}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                          marginRight: '10px',
                        }}
                      >
                        <IconGenerator iconName={icon} />
                        {icon.charAt(0).toUpperCase() + icon.slice(1)}
                      </Box>
                    </Box>
                  }
                  labelPlacement="start"
                  sx={{
                    padding: '0 10px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '5px',
                    height: '42px',
                    width: '170px',
                    display: 'flex',
                    justifyContent: 'start',
                    position: 'relative',
                    margin: '0 20px 10px 0',
                  }}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
      {selectedShortcut && (
        <Grid
          container
          sx={{
            marginY: '10px',
            width: '100%',
          }}
        >
          <Grid item>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DKTFormControl
                  {...field}
                  required
                  label={'Name'}
                  errors={errors}
                  size="small"
                  defaultValue={
                    selectedShortcut.slice(0, 1).toUpperCase() +
                    selectedShortcut.slice(1)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconGenerator iconName={selectedShortcut} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sx={{ width: '50%' }}>
            <Controller
              name="url"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DKTFormControl
                  {...field}
                  required
                  label={'Url'}
                  errors={errors}
                  size="small"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{ backgroundColor: '#1DB954' }}
          disabled={!isValid}
        >
          Add
        </Button>
      </DialogActions>
    </form>
  );
};
