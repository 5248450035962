import {
  ApiRef,
  BackstageIdentityApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  createPlugin,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';

import { OAuth2 } from '@backstage/core-app-api';

export const ssoDktPlugin = createPlugin({
  id: 'sso-dkt',
});

export type DecathlonSSOAuthProvider = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi;

export const decathlonSSOAuthProviderApiRef: ApiRef<DecathlonSSOAuthProvider> =
  createApiRef({
    id: 'internal.auth.decathlon',
  });

export const decathlonSSOAuthApiFactory = createApiFactory({
  api: decathlonSSOAuthProviderApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      configApi,
      discoveryApi,
      oauthRequestApi,
      defaultScopes: ['openid email profile'], // Set scopes here
      environment: configApi.getOptionalString('auth.environment'), // Set environment if needed here
    }),
});
