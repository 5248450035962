import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const dktCatalogPlugin = createPlugin({
  id: 'dkt_catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const RegisterToCatalog = dktCatalogPlugin.provide(
  createRoutableExtension({
    name: 'RegisterToCatalog',
    component: () => import('./pages').then(m => m.RegisterIndexPage),
    mountPoint: rootRouteRef,
  }),
);

export const DktCatalogPage = dktCatalogPlugin.provide(
  createRoutableExtension({
    name: 'CatalogPage',
    component: () => import('./pages').then(m => m.DktCatalogPage),
    mountPoint: rootRouteRef,
  }),
);
