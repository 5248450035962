export enum ENTITY_TYPE {
  COMPONENT = 'COMPONENT',
  PRODUCT = 'PRODUCT',
  SUPPORT_GROUP = 'SUPPORT_GROUP',
  SUB_DOMAIN = 'SUB_DOMAIN',
  DOMAIN = 'DOMAIN',
  COMPANY = 'COMPANY',
}

export enum API_TYPE {
  OPENAPI = 'OPENAPI',
  ASYNCAPI = 'ASYNCAPI',
  GRAPHQL = 'GRAPHQL',
  GRPC = 'GRPC',
}
