import { Link } from '@backstage/core-components';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';

export type IconLinkVerticalProps = {
  color?: 'primary' | 'secondary';
  href?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  tooltipText?: string;
  name: string;
};

const useIconStyles = makeStyles(
  theme => ({
    label: {
      color:
        theme.palette.mode === 'light'
          ? '#000000 !important'
          : '#ffffff !important',
      fontSize: `${theme.typography.fontSize}px!important`,
      fontWeight: 'bold !important',
      letterSpacing: 1.2,
      marginLeft: '10px !important',
    },
    link: {
      display: 'flex',
      gridGap: 4,
      justifyItems: 'start',
      textAlign: 'left',
      width: 'max-content',
    },
    primary: {
      color: '#000000 !important',
    },
    secondary: {
      color: '#3643BA',
    },
  }),
  { name: 'BackstageIconLinkVertical' },
);

export const IconLinkVertical: React.FC<IconLinkVerticalProps> = ({
  href = '#',
  icon = <LinkIcon />,
  onClick,
  tooltipText,
  name,
  color = `${name !== 'Github' ? 'secondary' : 'primary'}`,
}: IconLinkVerticalProps) => {
  const classes = useIconStyles();
  return (
    <Tooltip title={tooltipText ?? ''} placement="right">
      <Link
        className={`${classes.link} ${classes[color]}`}
        to={href}
        onClick={onClick}
        role="link"
      >
        <div
          style={{ display: 'flex', position: 'relative', marginRight: '10px' }}
        >
          {icon}
        </div>
      </Link>
    </Tooltip>
  );
};
