import { Card, Typography } from '@mui/material/';
import {
  ComponentEntity,
  Entity,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import { EntityTable, useEntity } from '@backstage/plugin-catalog-react';
import { LinkButton, TableColumn } from '@backstage/core-components';

import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import { BSSystem } from '@internal/backstage-plugin-models-common';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';

const componentEntityColumns: TableColumn<ComponentEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
];

const componentEntityHelpLink = '#';

const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
  entities as ComponentEntity[];

export function CustomComponentCard() {
  const { entity: system } = useEntity<BSSystem>();

  return (
    <Card>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h2">
          Components
        </Typography>
        <LinkButton
          to={`/register/systems/${system.metadata.name}/add-component`}
          state={{ system: system }}
          color="primary"
          variant="outlined"
        >
          <AddCircleIcon style={{ marginRight: 5 }} />
          Add components to {system?.metadata?.title} system
        </LinkButton>
      </Grid>
      <RelatedEntitiesCard
        variant="gridItem"
        title={'Components'} // title is normally of type string but the actual use allows any kind of JSX component
        entityKind="Component"
        relationType={RELATION_HAS_PART}
        columns={componentEntityColumns}
        emptyMessage="No component is part of this system"
        emptyHelpLink={componentEntityHelpLink}
        asRenderableEntities={asComponentEntities}
        tableOptions={{
          showTitle: false,
          toolbar: false,
        }}
      />
    </Card>
  );
}
