import Bookmark from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useStarredEntities } from '@backstage/plugin-catalog-react';

export const customActionsProps = () => {
  return [
    ({ entity }: any) => {
      return {
        icon: () => (
          <VisibilityIcon sx={{ color: '#3643BA', marginRight: '10px' }} />
        ),
        tooltip: 'View',
        onClick: () => {
          window.location.href = `/catalog/default/api/${entity.metadata.name}`;
        },
      };
    },
    ({ entity }: any) => {
      const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

      return {
        icon: () => {
          return isStarredEntity(entity) ? (
            <Bookmark sx={{ color: '#f79802' }} />
          ) : (
            <BookmarkBorderIcon sx={{ color: '#3643BA' }} />
          );
        },
        tooltip: 'Add to favorites',
        onClick: () => {
          toggleStarredEntity(entity);
        },
      };
    },
  ];
};
