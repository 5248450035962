'use client';

import { Alert, Snackbar } from '@mui/material';
import { FC, useEffect } from 'react';

import { useNotification } from '../../contexts';

interface IProps {
  duration?: number;
}

export const SimpleNotification: FC<IProps> = ({ duration = 5000 }) => {
  const { notification, setNotification } = useNotification();

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification(null);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [notification, setNotification, duration]);

  if (!notification) return null;

  return (
    <Snackbar
      role="alert"
      open={!!notification}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={() => setNotification(null)}
    >
      <Alert
        onClose={() => setNotification(null)}
        severity={notification?.severity ?? 'info'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};
